@import "colors";
@import "colors";

.FullBlog-container {
    background-color: $white;
    min-height: 100vh;

    a{
        text-decoration: none;
        background-color: rgb(238, 238, 238);
        color: rgb(77, 77, 77);
        font-weight: 700;
    }

    div,
    p,
    h5 {
        font-size: 19px;
        font-family: $text;
        line-height: 1.9;
    }

    .FullBlog {
        // width: 100vw;
        display: flex;
        padding: 3rem;
        flex-direction: column;
        gap: 3rem;
        align-items: center;
        overflow-x: hidden;

        img {
            width: 100%;
        }
    }

}
