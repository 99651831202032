@import "colors.scss";

.aboutus-container {
    background-color: $white;
    flex-direction: column;
    // height: 100vh;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    gap: 20px;

    .aboutus {
        display: flex;
        align-items: flex-start;
        padding: 5%;
        font-family: $text;
        gap: 2%;

        .content {
            width: 50%;
            font-size: 19px;
            font-family: $text;
            line-height: 1.9;
        }

        .img-container {
            width: 50%;

            img{
                width: 100%
            };
        }
    }

}


