@import "colors.scss";


.intro {
    // height: calc(100vh - 50px);
    height: 100vh;
    background-image:url("../Assets/Steel shot manufacturing.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100%;
    width: auto;

    video {
        width: 100%;
        // margin-top: -50px;
        height: 100vh;
        object-fit: cover;
        // display: none;
        filter: contrast(150%);
        // filter: blur(5px);
    }

    div {
        width: 100%;
        // height: calc(100vh - 50px);
        height: 100vh;
        position: absolute;
        top: 0;
        background-color: rgba(0, 0, 0, 0.658);
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 20px;

        h1 {
            width: 80%;
            font: 300 54px "Montserrat", sans-serif;
            font-weight: 700;
            color: $white;
            text-align: center;
        }

        p {
            width: 80%;
            text-align: center;

            font: 500 20px $text;
            color: $white;
            letter-spacing: 3px;
            line-height: 1.4;
         
        }
    }
}