@import "colors.scss";


div .concatcus {
    font-family: $text;
    width: 100%;
    min-height: 100vh;
    background-color: #f0f0f0;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    // padding: 4%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    gap: 20px;

    form {
        padding: 5%;
        width: 40%;
        display: flex;
        flex-direction: column;

        div {
            margin-bottom: 15px;
display: flex;
flex-direction: column;

            label {
                margin-bottom: 5px;
                font-weight: bold;
                color: #555;
            }

            input,
            textarea {
                width: 100%;
                padding: 10px 0;
                margin-bottom: 2rem;
                border: 1px solid #ddd;
                border-radius: 4px;
                font-size: 16px;
            }

            textarea {
                resize: vertical;
                min-height: 100px;
            }
        }

        button {
            padding: 10px;
            background-color: $blue;
            color: #fff;
            border: none;
            border-radius: 4px;
            font-size: 16px;
            cursor: pointer;
            transition: all 0.5s;

            &:hover {
                background-color: #1f1fc1;
                transform: skewX(-10deg);

            }
        }
    }
    @media screen and (max-width: 820px) {
        form {
            width: 80% !important;
        }
    }
}