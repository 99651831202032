.container {
    margin: 0 auto;
    // padding: 4rem;
    width: 100%;

    .accordion {
      .accordion-item {
        border-bottom: 1px solid #e5e5e5;
    
        button[aria-expanded='true'] {
          border-bottom: 1px solid #03b5d2;
        }
    
        button {
          position: relative;
          display: block;
          text-align: left;
          width: 100%;
          padding: 1em 0;
          color: white;
          font-size: 1.15rem;
          font-weight: 400;
          border: none;
          background: none;
          outline: none;
    
          &:hover,
          &:focus {
            cursor: pointer;
            color: white;
          font-weight: 600;

          }
    
          &:hover::after,
          &:focus::after {
            cursor: pointer;
            color: #03b5d2;
            border: 1px solid #03b5d2;
          }
    
          .accordion-title {
            padding: 1em 1.5em 1em 0;
          }
    
          .icon {
            display: inline-block;
            position: absolute;
            top: 18px;
            right: 0;
            width: 22px;
            height: 22px;
            border: 1px solid;
            border-radius: 22px;
    
            &::before {
              display: block;
              position: absolute;
              content: '';
              top: 9px;
              left: 5px;
              width: 10px;
              height: 2px;
              background: currentColor;
            }
    
            &::after {
              display: block;
              position: absolute;
              content: '';
              top: 5px;
              left: 9px;
              width: 2px;
              height: 10px;
              background: currentColor;
            }
          }
    
          &[aria-expanded='true'] {
            color: #000000;
            .icon::after {
              width: 0;
            }
          }
    
          & + .accordion-content {
            opacity: 1;
            // max-height: 9em;
            transition: all 200ms linear;
            will-change: opacity, max-height;
          }
        }
      }
    
      .accordion-content {
          opacity: 0;
          max-height: 0;
          overflow: hidden;
          transition: opacity 200ms linear, max-height 200ms linear;
          will-change: opacity, max-height;
        
          p {
            font-size: 1rem;
            font-weight: 300;
            margin: 2em 0;
            color: white;
          }
        }
    }
  }
  
  
  