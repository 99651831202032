@import url('https://fonts.googleapis.com/css2?family=Bangers&family=Kalam:wght@400;700&family=Montserrat:wght@100;200;300;400;600;700;900&display=swap');

*{
    padding: 0;
    margin: 0;
}

body{
    overflow-x: hidden;
    box-sizing: border-box;
}