@import "colors.scss";

footer {
    width: 100%;
    height: 100%;
    background-color: rgb(49, 49, 49);
    position: fixed;
    bottom: 0%;
    z-index: -1;
    padding: 8rem 6rem;
    box-sizing: border-box;
    display: grid;
    grid-template-columns: 4fr 1fr;

    span{
        margin-bottom: 20px;
    }
    h4 {
        color: $white;
        font: italic 800 50px $text;
    }

    aside {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
    }

    a {
        color: $white;
        text-transform: uppercase;
        font: 500 14px $text;
        text-decoration: none;
        letter-spacing: 3px;
        line-height: 1.5;
        transition: all 0.5s;
    }

    div {
        color: $white;
        display: flex;
        flex-direction: column;
        padding: 4rem;
        &:first-of-type {
            align-items: flex-start;
            span {
                text-decoration: none;
            font-family: $text;
            color: $white;
                
                
            }
            .social-icons{
                display: flex;
                flex-direction: row;
                padding: 0;
                a{
                    margin-right: 20px;
                }
            }
        }
        &:last-of-type {
            align-items: flex-end;
        }

        h5 {
            font: 500 22px $text;
            line-height: 1;
            margin: .5rem 0;
        }

        a {
            text-decoration: none;
            font-family: $text;
            color: $white;
        }

    }

    p {
        font: 500 12px $text;
        color: $white;
        letter-spacing: 3px;
        line-height: 1;
        text-transform: uppercase;
        position: absolute;
        bottom: 5%;
        left: 50%;
        transform: translateX(-50%);
        text-align: center;
    }
}

.footer {
    width: 100%;
    height: 100vh;
    background-color: transparent;
    z-index: -2;
    position: absolute;
}