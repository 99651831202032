@import "colors.scss";

.blog-container {
    background-color: $white;
    min-height: 100vh;
    display: flex;
    flex-direction: column;

    .blogs {
        border-top: 1px solid rgb(189, 189, 189);
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        padding: 5%;
        gap: 3rem;

        // width: 100%;
        a {
            text-decoration: none;


            .BlogBox {
                background-color: #F0F0F0;
                border-radius: 10px;
                overflow: hidden;
                width: 350px;

                &:hover {
                    box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
                }

                img {
                    width: 350px;
                }

                h2 {
                    color: black;
                    font-size: 19px;
                    font-family: $text;
                    line-height: 1.9;
                    margin: 1rem;
                    text-align: center;
                }
            }
        }
    }
}