@import "colors.scss";

section{
    
    display: flex;
    justify-content: center;
    align-items: center;

    > div{
         max-width: 48rem;
        min-height: 120vh;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        box-sizing: border-box;
        justify-content: space-evenly;
    }
    h2{
        width: 80%;
        font: 300 54px $heading;
        font-weight: 700;
    }
    p{
        font-size: 19px;
        font-family: $text;
        line-height: 1.9;
    }
    button{
        font: 600 14px $text;
        padding: 0.9rem 1.25rem;
        border: none;
        transform-origin: bottom;
        transition: transform 0.5s;
        letter-spacing: 2px;
        border-radius: 7px;

        &:hover {
          transform: skewX(-10deg) !important;
        }
    }
   
        
    
}
