@import "colors.scss";

.header-contaier{
    background-image:url("../Assets/Steel shot manufacturing.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 300px;
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $white;
    font: 300 45px $heading !important;
    z-index: 0;
    text-align: center;
    &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background-color: rgba(0, 0, 0, 0.616);
        z-index: 0;
      }

      h1{
        z-index: 1;
        margin: 6rem 0 2rem 0 ;
width: 80%;
      }
}
